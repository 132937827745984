import { AssetData, PeopleDraw } from '_types';
import { MetadataElevator } from '_types/features';
import {
    EquipmentData,
    PeopleListItem,
    PeopleLiveItem,
} from '_types/queries';
import * as THREE from 'three';

const assetsColorMap = {
    'normal': 0x1ed273,
    'sync': 0x1ed273,
    'ATS': 0x1ed273,
    'PRL_L': 0x1ed273,
    'PAD': 0x1ed273,
    'PRC': 0x1ed273,
    'RES_E': 0x1ed273,
    'inspection': 0xffa023,
    'fireman': 0xffa023,
    'EPD': 0xffa023,
    'DOP_CS': 0xffa023,
    'OSS': 0xffa023,
    'RUNNING': 0x22dd22,
    'NOT RUNNING': 0xff3333,
};

export const densityAreaColorMap = {
    'A': 0x0071b9,
    'B': 0x00ffff,
    'C': 0x58ab27,
    'D': 0xfcc400,
    'E': 0xf6a800,
    'F': 0xbe2126,
};

export const congestionColorMap = {
    'MIN_CONGESTED': '#ffff00', // yellow
    'MED_CONGESTED': '#ffa500', // orange
    'CONGESTED': '#ff0000', // red
};

export function PeopleLiveDataToPeopleDraw(person: PeopleLiveItem): PeopleDraw {
    const position = {
        x: +person.x,
        y: +person.y,
        z: +person.z,
    };
    const id = person.ObjectID;

    return { position, id };
}

export function PeopleListItemToPeopleDraw(person: PeopleListItem): PeopleDraw {
    const position = JSON.parse(person.position);
    const id = '' + person.ObjectID;

    return { position, id };
}

export const getElevatorNameFromId = (id: number, elevators: MetadataElevator[]): string => {
    if (!elevators) return '';

    let result = '';
    elevators.forEach((elevator) => {
        if (elevator.id === id) result = elevator.name;
    });

    return result;
};

export function changeAssetsColor(assets: MetadataElevator, events: EquipmentData) {
    const assetDataArray: AssetData[] = [];

    const color = new THREE.Color(assetsColorMap[events.status] ?? 0x4f9fdd);

    assetDataArray.push({
        name: assets?.name,
        color: color,
        opacity: 0.15,
    });

    return assetDataArray;
}

export function getCloserSmallerNumber(numList: number[], givenNum: number): number {
    let smallerNum = Number.NEGATIVE_INFINITY;

    for (const num of numList) {
        if (num <= givenNum && num > smallerNum) {
            smallerNum = num;
        }
    }

    return smallerNum;
}
