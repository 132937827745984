import { gql } from '@apollo/client';

/**
 * Subscribes to escalator alerts
 */
export const SUBSCRIBESCALATORALERTS = gql`
    subscription MySubscription($site_id: String!) {
        onEscalatorDirectionChangeAlert(site_id: $site_id) {
            alert_type
            direction
            eq
            message
            read_status
            site_id
            status
            timestamp
            title
            ttl_time
        }
    }
`;

export const SUBSCRIBEALERTS = gql`
    subscription MySubscription {
        onUpdateVideoMonitoringAlerts {
            alarm_status
            alarm_id
            alarm_type
            camera_id
            equipment_id
            genai_message
            message
            site_id
            time_stamp
            version
        }
    }
`;

export const GETVIDEOMONITORINGALERTS = gql`
    query MyQuery($site_id: String) {
        getVideoMonitoringAlerts(site_id: $site_id) {
            alarm_id
            alarm_status
            alarm_type
            camera_id
            equipment_id
            genai_message
            message
            read_status
            site_id
            time_stamp
            timestamp
            version
        }
    }
`;

export const SETVIDEOMONITORINGALERTSREAD = gql`
    mutation MyMutation($time_stamp: String!, $site_id: String!) {
        updateReadStatusVideoMonitoringAlerts(
            time_stamp: $time_stamp
            read_status: 1
            site_id: $site_id
        ) {
            read_status
        }
    }
`;

/**
 * Get All Escalator alerts
 */
export const GETESCALATORALERTS = gql`
    query MyQuery($site_id: String) {
        getEsclatorDirectionChangeAlerts(site_id: $site_id) {
            eq
            timestamp
            status
            direction
            site_id
            ttl_time
            read_status
            message
            title
            alert_type
        }
    }
`;

/**
 * Updates the read status of escalator alerts
 * @param eq - equipment name
 * @param timestamp - timestamp of the alert
 * @returns - eq, timestamp, read_status
 */
export const SETESCALATORALERTSREAD = gql`
    mutation MyMutation($eq: String!, $timestamp: Int!) {
        updateReadStatusEscalatorDirectionChange(
            eq: $eq
            read_status: "read"
            timestamp: $timestamp
        ) {
            alert_type
            direction
            message
            eq
            site_id
            read_status
            timestamp
            status
            title
            ttl_time
            view {
                key
                label
            }
        }
    }
`;

/**
 * People Congestion Alert Subscription
 * @param site_id - site id
 * @returns - site_id, z_axis_2, z_axis_1, x_axis_2, x_axis_1, zone_name, status
 */
export const SUBSCRIBECONGESTIONALERTS = gql`
    subscription MySubscription($site_id: String) {
        onCongestionAlert(site_id: $site_id) {
            site_id
            z_axis_2
            z_axis_1
            x_axis_2
            x_axis_1
            zone_name
            status
            message
        }
    }
`;

/**
 * Get all poeple congestion alerts
 * @param site_id - site id
 * @returns - site_id, z_axis_2, z_axis_1, x_axis_2, x_axis_1, zone_name, status
 */
export const GETCONGESTIONALERTS = gql`
    query MyQuery($site_id: String) {
        getCongestionAlerts(site_id: $site_id) {
            site_id
            status
            x_axis_2
            x_axis_1
            z_axis_1
            z_axis_2
            zone_name
            message
        }
    }
`;
