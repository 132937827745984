import { gql } from '@apollo/client';
export * from './simulation_queries';
export * from './dashboard_queries';
export * from './prod_queries';
export * from './alerts_queries';

export const SUBSCRIBPTIONPEOPLEDATA = gql`
    subscription MySubscription($sender_id: Int) {
        onUpdateUlisseData(sender_id: $sender_id) {
            sender_id
            message
        }
    }
`;

export const GETEQUIPMENTS = gql`
    query MyQuery {
        listElevatorEvents {
            items {
                EquipmentNumber
                position
                mode
            }
        }
    }
`;

export const GETPEOPLE = gql`
    query MyQuery {
        listLiveJourneyTime {
            position
        }
    }
`;

export const GETTRAINS = gql`
    query MyQuery {
        listMetroEvents {
            items {
                current_status
                direction_id
                route_id
                stop_id
                vehicle_id
            }
        }
    }
`;

export const GETJOURNEYTIME = gql`
    query MyQuery {
        listLiveJourneyEvents {
            items {
                floor_traffic_type
                avg_journey_time_s
                floor_name
                traffic_type
            }
        }
    }
`;

export const GETWEATHER = gql`
    query MyQuery {
        getTemperatureDataRDS {
            Air_temperature
            Station_real_name
            Weather
        }
    }
`;

export const GETEQUIPMENTUSAGE = gql`
    query MyQuery {
        getEquipmentList {
            human {
                count
                eq_name
            }
        }
    }
`;

// TODO: Might need to delete this
export const GETTOTALCOUNTSINOUT = gql`
    query MyQuery {
        getTotalCountsInOut {
            incoming_people_count
            outgoing_people_count
            incoming_bike_count
            outgoing_bike_count
            AE01_2_people
            AE01_2_bike
            AE03_people
            AE03_bike
            AF04_people
            AF04_bike
        }
    }
`;

export const GETSTATIONINOUTCOUNT = gql`
    query MyQuery($site_id: String!) {
        getStationInOutCount(site_id: $site_id) {
            incoming
            outgoing
            reset_time
            site_id
        }
    }
`;
export const GETLATENCY = gql`
    query MyQuery {
        getLatencyLive {
            latency
        }
    }
`;

export const GETPEOPLEONPLATFORM = gql`
    query MyQuery {
        getPlatformCount {
            platform_count
        }
    }
`;

export const GETPOWERCONSUMPTION = gql`
    query MyQuery {
        getLiveEnergyData {
            items {
                power_consumed
                id
            }
        }
    }
`;

export const GETBRIEFINGS = gql`
    query MyQuery {
        liveGenAiPrompt {
            items {
                response
                time
            }
        }
    }
`;

export const GETESCALATORPEOPLEUSAGE = gql`
    query MyQuery {
        listEscalatorUsageData {
            AB06_bike
            AB06_people
            AB07_bike
            AB07_people
        }
    }
`;

export const SUBSCRIBE_TO_PEOPLE_MOVEMENT = gql`
    subscription MySubscription($siteId: String!) {
        onPeopleMovement(site_id: $siteId) {
            site_id
            people {
                ObjectID
                x
                y
                z
            }
        }
    }
`;

export const SUBSCRIBE_TO_EQUIPMET_STATUS = gql`
    subscription MySubscription($site_id: String!) {
        onEquipmentStatus(site_id: $site_id) {
            eq
            site_id
            direction
            prev_direction
            status
            alert_type
        }
    }
`;

export const GET_EQUIPMET_STATUS = gql`
    query MyQuery($site_id: String!) {
        getStationEquipmentStatus(site_id: $site_id) {
            alert_type
            direction
            eq
            prev_direction
            site_id
            status
        }
    }
`;
