import { Visualization } from 'components/Visualizer/visualization';
import { congestionColorMap, PeopleLiveDataToPeopleDraw } from '../util';
import { LDM } from './liveDataManager';
import { CongestionAlertQuery, PeopleDraw, PeopleLiveItem } from '_types';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { apolloClient } from 'app/clients/apolloClient';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';

import { GETCONGESTIONALERTS, SUBSCRIBE_TO_PEOPLE_MOVEMENT, SUBSCRIBECONGESTIONALERTS } from '_queries';

interface OnPeopleMovementResponse {
    site_id: string;
    people: PeopleLiveItem[];
}

interface SubscriptionResponse {
    onPeopleMovement?: OnPeopleMovementResponse;
}

export class UsersDataManager implements LDM {
    viz: Visualization;
    events: PeopleDraw[];
    client: ApolloClient<NormalizedCacheObject>;
    site_id: string;
    peopleSubscription: Subscription;
    congestionSubscription: Subscription;

    constructor(site_id) {
        this.site_id = site_id;

        this.client = apolloClient;
        this.events = [];
    }

    public SetMainViewSubscription(viz: Visualization) {
        this.viz = viz;

        // check if site_id is not an empty string, otherwise return
        if (this.site_id === '') {
            return;
        }

        // Check Initially Congestion 
        this.client.query({
            query: GETCONGESTIONALERTS,
            variables: { site_id: this.site_id },
        }).then(({ data }: { data: { getCongestionAlerts: CongestionAlertQuery[] } }) => {
            data?.getCongestionAlerts.forEach((alert) => {
                this.processCongestionZone(alert);
            });
        });

        // Subscribe to People Movement Data
        const subscription = this.client
            .subscribe({
                query: SUBSCRIBE_TO_PEOPLE_MOVEMENT,
                variables: { siteId: this.site_id },
            })
            .subscribe({
                next: ({ data }: { data: SubscriptionResponse }) => {
                    const onPeopleMovement = data?.onPeopleMovement;
                    if (onPeopleMovement) {
                        const { people } = onPeopleMovement;
                        this.subscribeFunction.bind(this)(people);
                    } else {
                        console.warn('No data received or onPeopleMovement is null.');
                    }
                },
                error: (e) => console.error(e),
            });

        this.peopleSubscription = subscription;

        // Subscribe to congestion alerts
        this.congestionSubscription = this.client
            .subscribe({
                query: SUBSCRIBECONGESTIONALERTS,
                variables: { site_id: this.site_id },
            })
            .subscribe({
                next: ({ data: { onCongestionAlert: alert } }) => {
                    this.processCongestionZone(alert);
                },
                error: (e) => console.error(e),
            });
    }

    private subscribeFunction(message: PeopleLiveItem[]) {
        this.events = message.map((person) => PeopleLiveDataToPeopleDraw(person));

        this.viz.people.UpdateMeshes(this.events);
    }

    public StopMainViewSubscription() {
        this.peopleSubscription?.unsubscribe();
        this.congestionSubscription?.unsubscribe();
        this.viz.people.HidePeople();
    }

    private processCongestionZone(alert: CongestionAlertQuery) {
        if (alert.status === 'CONGESTED' || alert.status === 'MED_CONGESTED' || alert.status === 'MIN_CONGESTED') {
            const color = congestionColorMap[alert.status];
            this.viz.people.setAlertZone(alert.zone_name, {
                min: { x: alert.x_axis_1, y: -20, z: alert.z_axis_2 },
                max: { x: alert.x_axis_2, y: -20, z: alert.z_axis_1 },
                color: color,
            });
        } else {
            this.viz.people.removeAlertZone(alert.zone_name);
        }
    }
}
