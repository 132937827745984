import {
    ApolloClient,
    NormalizedCacheObject,
    ObservableQuery,
    OperationVariables,
} from '@apollo/client';
import { apolloClient } from 'app/clients/apolloClient';
import { GETTRAINS } from '_queries';
import { AssetData, TrainsInStation } from '_types';
import { TrainData } from '_types/queries';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';
import { LDM } from './liveDataManager';
import { Visualization } from 'components/Visualizer/visualization';

export class TrainDataManager implements LDM {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    query: ObservableQuery<TrainData, { site_id: string }>;
    trainsInStation?: TrainsInStation;
    assets: AssetData[];
    assetSubscription: Subscription;
    site_id: string;

    constructor(site_id: string) {
        this.site_id = site_id;
        this.client = apolloClient;
        this.SetQuery();
    }

    private SetQuery() {
        // check if site_id is not an empty string, otherwise return
        // if (this.site_id === '') {
        //     return;
        // }

        this.query = this.client.watchQuery({
            query: GETTRAINS,
            variables: { site_id: this.site_id },
        });
    }

    public SetMainViewSubscription(viz: Visualization, pollInterval: number) {
        this.viz = viz;

        this.query.startPolling(pollInterval);
        const subscribption = this.query.subscribe({
            next: this.SubscribeFunctionMainView.bind(this),
            error: (e) => console.error(e),
        });
        this.assetSubscription = subscribption;
    }

    private SubscribeFunctionMainView({ data }: { data: TrainData | undefined }) {
        const trainList = data?.listMetroEvents.items;

        if (!trainList) return;

        // TODO: Make it generic for the whole metro system
        const in_station = trainList.filter(
            (train) => train.stop_id === 'Rautatientori' && train.current_status === 1
        );

        const to_east = in_station.find((train) => train.direction_id === 0);
        const to_west = in_station.find((train) => train.direction_id === 1);
        const trainAssets = this.viz.static.trainAssets;

        if (!this.trainsInStation) {
            if (to_east) {
                trainAssets.SetInitialVisible('to_east', to_east.vehicle_id);
            }
            if (to_west) {
                trainAssets.SetInitialVisible('to_west', to_west.vehicle_id);
            }
        } else {
            if (this.trainsInStation.to_east && !to_east) trainAssets.TrainToEast();
            if (!this.trainsInStation.to_east && !!to_east)
                trainAssets.TrainFromWest(to_east?.vehicle_id ?? '');
            if (this.trainsInStation.to_west && !to_west) trainAssets.TrainToWest();
            if (!this.trainsInStation.to_west && !!to_west)
                trainAssets.TrainFromEast(to_west?.vehicle_id ?? '');
        }
        this.trainsInStation = {
            to_east: !!to_east,
            to_west: !!to_west,
        };
    }

    public StopMainViewSubscription() {
        if (!this.assetSubscription) return;
        this.assetSubscription.unsubscribe();
    }
}
